body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.timeline {
  padding-left: 1rem;
  position: relative;
}
.timeline__item {
  position: relative;
  color: rgb(87,96,106);
  padding: 0 1rem;
  margin-left: -1rem;
  background-color: #f5f5f5;
  margin-bottom: 2rem;
}
.timeline__item--reference {
  padding-left: 2rem;
}
/* Line */
.timeline__item::before {
  content: "";
  position: absolute;
  border-left: solid 1px #ccc;
  top: 1rem;
  left: 0;
  height: calc(100% + 2rem - 1px);
  z-index: 1;
}
.timeline__item:last-child::before {
  display: none;
}
/* Indicator dot */
.timeline__item::after {
  background: rgb(87,96,106);
  border-radius: 50%;
  content: "";
  height: 7px;
  left: -3px;
  position: absolute;
  top: 1rem;
  width: 7px;
  z-index: 2;
}
.comment__header {
  background: rgb(246,248,250);
  padding: .5rem 1rem;
  border-bottom: 1px solid #ccc;
}

.dashboard__grid {
  display: flex;
  flex-direction: "column"
}

.dashboard__grid > .grid__item {
  flex: 1;
  margin-right: .5rem;
}
